.Loader {
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.Loader-infinite {
  position: absolute;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  left: calc(50% - 40px);
  bottom: 40px;
  padding: 0;
}

.Loader-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}
